.contactcolor{
    background-color: rgb(59, 54, 54);
    padding: 05%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}

.update1{
    background-color: rgb(0, 14, 94);
    padding: 02%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}