.mainh{
    color: rgb(212, 83, 83);
}

.a2zservice {
    position: relative;
    width: 100%;
  }
  
  .image5 {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle5 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .a2zservice:hover .image5 {
    opacity: 0.5;
  }
  
  .a2zservice:hover .middle5 {
    opacity: 1;
  }
  
  .text {
   
    color: rgb(230, 9, 75);
    
  }


  .umahardwarebg{
    
    background-color: rgb(0, 0, 0);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}